import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, EventManager } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth/auth.module';
import { APILanguageInterceptor } from '@i18n/services/api-language.interceptor';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { ODataModule } from 'angular-odata';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ODATA_CONFIGS } from './core/odata.config';
import { APIBaseURLInterceptor } from './core/services/api-base-url.interceptor';
import { DocumentsRepositoryService } from './core/services/documents.repository.service';
import { NativeEventManager } from './core/services/native-event-manager.service';
import { AuthService } from './features/auth/services/auth.service';
import { DrawingModule } from './features/drawing/drawing.module';
import { I18nModule } from './features/i18n/i18n.module';
import { MaterialModule } from './material/material.module';
import { AppStateService } from './state/app.state.service';
import { IconModule } from './uikit/icon/icon.module';
import { SnackbarModule } from './uikit/snackbar/snackbar.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    DrawingModule,
    HttpClientModule,
    I18nModule.forRoot(),
    IconModule,
    MaterialModule,
    SnackbarModule,
    ODataModule.forRoot(...ODATA_CONFIGS),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([AppStateService], {
      developmentMode: !environment.production
    })
  ],
  providers: [
    AuthService,
    DocumentsRepositoryService,
    { provide: EventManager, useClass: NativeEventManager },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIBaseURLInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APILanguageInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
