<div>
  <p class="title">{{ data.title }}</p>
  <span *ngIf="data.body" class="body">{{ data.body }}</span>
</div>

<button *ngIf="data.action" class="action" type="button" (click)="data.action.callback()">
  {{ data.action.label }}
</button>
<vlt-icon
  *ngIf="data.icon"
  color="primary"
  size="small"
  type="button"
  [name]="data.icon"
></vlt-icon>
<vlt-spinner *ngIf="data.isLoading"></vlt-spinner>
